<template>
  <div class="Issue">
    <div class="crumbs flex">
      <router-link to="/questionnaire">
        <a-icon class="return" type="left" />
      </router-link>
      <!--<div>-->
      <!--<span @click="_skipPage(0)" :class="{'crumbs-item':'crumbs-item','ant-menu-item-selected':itemActive==0?true:''}">创建项目 <span v-show="itemActive===0" class="ant-btn-primary"></span></span>-->
      <!--<a-icon class="crumbs-icon" type="right"/>-->
      <!--<span @click="_skipPage(1)" :class="{'crumbs-item':'crumbs-item','ant-menu-item-selected':itemActive==1?true:''}">发布项目 <span v-show="itemActive===1" class="ant-btn-primary"></span></span>-->
      <!--<a-icon class="crumbs-icon" type="right"/>-->
      <!--<span @click="_skipPage(2)" :class="{'crumbs-item':'crumbs-item','ant-menu-item-selected':itemActive==2?true:''}">统计数据 <span v-show="itemActive===2" class="ant-btn-primary"></span></span>-->
      <!--</div>-->
    </div>
    <div class="issue-container">
      <div class="title">链接分享</div>
      <div class="skip-url flex">
        <a-input v-model="copyUrl" ref="copy" />
        <a-button type="primary" class="copy-btn" ghost @click="_copyUrl"
          >复制链接</a-button
        >
      </div>
      <div class="title">二维码分享</div>
      <div class="qrcode flex">
        <div class="qrcode-container">
          <div class="qrcode-title">二维码</div>
          <div class="qrcode-des">用户可以通过扫描二维码答题</div>
          <div class="qrcode-content" ref="qrCodeUrl"></div>
          <div style="text-align: center;margin-top:30px">
            <a-button type="primary" ghost
              ><a :href="url" download="二维码">下载二维码</a></a-button
            >
          </div>
        </div>
        <div
          class="qrcode-container"
          style="height: 350px;flex: 1;margin-left: 15px;position: relative"
        >
          <div
            style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);font-size: 18px;"
          >
            内容开发中
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'Issue',
  data () {
    return {
      itemActive: 1,
      copyUrl: '',
      url: ''
    }
  },
  mounted () {
    this.$axios.get(5836111, { Qid: this.$route.query.id }, res => {
      if (res.data.code == 1) {
        this.copyUrl = res.data.data
        this.creatQrCode()
      } else {
        this.$message.error(res.data.msg)
      }
    })
  },
  methods: {
    creatQrCode () {
      let self = this
      let qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: self.copyUrl,
        width: 140,
        height: 140,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })

      setTimeout(function () {
        self.url = document
          .getElementsByClassName('qrcode-content')[0]
          .childNodes[1].getAttribute('src')
      }, 100)
    },
    _copyUrl () {
      this.$refs.copy.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
    },
    _skipPage (i) {
      if (i == 0) {
        this.$router.push({
          path: 'addQuestionnaire',
          query: { type: this.$route.query.type }
        })
      } else if (i == 2) {
        this.$router.push({
          path: 'statisticsQuestionnaire',
          query: { type: this.$route.query.type, id: this.$route.query.id }
        })
      }
    }
  },
  components: {
    QRCode
  }
}
</script>

<style scoped lang="less">
.Issue {
  .crumbs {
    position: relative;
    height: 64px;
    padding-right: 30px;
    line-height: 64px;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    color: #666;
    font-size: 14px;
    .return {
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      font-size: 18px;
      color: #9d9d9d;
    }
    .crumbs-icon {
      margin-left: 30px;
      margin-right: 30px;
      font-size: 14px;
      color: #9d9d9d;
    }
    .crumbs-item {
      position: relative;
      cursor: pointer;
      span {
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 100%;
        height: 2px;
      }
    }
  }
  .issue-container {
    width: 900px;
    margin: 0 auto;
    .title {
      margin: 20px 0;
      font-size: 18px;
      color: #333;
    }
    .skip-url {
      padding: 25px;
      background: #fff;
      border-radius: 4px;
      input {
        height: 36px;
        font-size: 16px;
      }
      .copy-btn {
        height: 36px;
        margin-left: 25px;
      }
    }
    .qrcode {
      .qrcode-container {
        width: 280px;
        height: 350px;
        padding: 25px;
        background: #fff;
        border-radius: 4px;
        .qrcode-title {
          color: #333;
          font-size: 18px;
          text-align: center;
        }
        .qrcode-des {
          margin-top: 5px;
          margin-bottom: 25px;
          color: #999;
          font-size: 14px;
          text-align: center;
        }
        .qrcode-content {
          width: 140px;
          height: 140px;
          margin: 0 auto;
          padding: 3px;
        }
      }
    }
  }
}
</style>
